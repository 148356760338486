<template>
  <v-app id="layout" ref="appLayout">
    <Lnb
      v-if="accountAvailable && envLayout.lnb.visible"
      ref="drawer"
      :subItemEnabled="subItemEnabled"
    ></Lnb>
    <Snb v-if="accountAvailable && envLayout.snb.visible"></Snb>
    <v-content>
      <v-container fluid class="wrap__contents" :style="overflowHidden" id="contentScrollTarget" @scroll="onScroll">
        <Gnb 
          v-if="accountAvailable && envLayout.gnb.visible"
          @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
        ></Gnb>
        <!-- <j-comm-notification v-if="isHome" v-model="noticeOpen"></j-comm-notification> -->
        <div class="j__contents_footer" :style="styleWrapperOverflowHidden">
          <div class="j__contents">
            <Ftb v-if="accountAvailable && filebrowserActivated" />
            <Anb v-else-if="accountAvailable && envLayout.anb.visible" />
            <!-- CONTENTS -->
            <router-view class="j__contents_router_view"></router-view>
          </div>
          <Fnb
            v-if="accountAvailable && envLayout.fnb.visible"
            @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
          ></Fnb>
        </div>
      </v-container>
    </v-content>

    <j-overlay :value="loading">
      <j-spinner-orbit
        :border="3"
        :color="'#b3e5fc'"
        :animation-duration="1200"
        :size="320"
      />
    </j-overlay>

    <a v-if="commentable" v-ripple class="btn-comment-layout" title="comment" @click="onCommentOpen">
      <i class="mdi mdi-comment-text-multiple-outline"></i>
    </a>
    

    <!-- Slide Modals -->
    <j-comm-comments v-model="commentOpen" @updateComment="onUpdateComment"></j-comm-comments>
    <j-file-manager v-model="filemanagerOpen"></j-file-manager>
    <!-- <j-comm-messenger v-if="accountAvailable" v-model="messengerOpen"></j-comm-messenger> -->
    <!-- Popup -->
    <!-- Popup User Info. by Snb User Avatar -->
    <j-comments-board v-model="commentsOpen"></j-comments-board>
    <j-snb-user-info v-model="userOpen" @inputUser="inputUser"></j-snb-user-info>
    <j-notice-popup v-if="noticeAvailable"  v-model="noticeOpen" @inputModal="inputModal"></j-notice-popup>
    <j-modal-overlay-img-popup v-model="popupImgOpen" :imgPath="imgPath" :dimention="imgDimention"></j-modal-overlay-img-popup>
    <j-modal-popup-equipment v-model="popupEqOpen" :params="popupEqParams" :updateComment="updateComments"></j-modal-popup-equipment>
    <j-modal-popup-hl-equipment v-model="popupHlEqOpen" :params="popupHlEqParams"></j-modal-popup-hl-equipment>
    <j-modal-popup-free v-model="popupFreeOpen" :params="popupFreeParams" @call-sender="onCallSender"></j-modal-popup-free>
    <j-modal-popup-hls v-model="popupHlsOpen" :params="popupHlsParams" @call-sender="onCallSender" @request-action="onRequestAction"></j-modal-popup-hls>

  </v-app>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'

import __C from '@/primitives/_constant_'
import _configDefault from '@/primitives/_configDefault'
import { ConfigService } from '@/services'
import JCommComments from '@/components/JCommComments'
import JCommNotification from '@/components/JCommNotification'
import JCommMessenger from '@/components/JCommMessenger'
import JFileManager from '@/components/JFileManager'
import JSnbUserInfo from '@/components/JSnbUserInfo'
import JCommentsBoard from '@/components/JCommentsBoard'
import JModalPopupFree from '@/components/JModalPopupFree'
import JModalPopupHls from '@/components/JModalPopupHls'
import JModalPopupEquipment from '@/components/JModalPopupEquipment'
import JModalPopupHlEquipment from '@/components/JModalPopupHLEquipment'
import JModalOverlayImgPopup from '@/components/JModalOverlayImgPopup'
import JNoticePopup from '@/components/JNoticePopup'
import Comment from '@/mixins/comment.mixin'
import Loading from '@/mixins/loading.mixin'

import Lnb from './JLnb'
import Gnb from './JGnb'
import Anb from './JAnb'
import Snb from './JSnb'
import Fnb from './JFnb'

import Ftb from './JFtb'

export default {
  mixins: [
    Comment,
    Loading
  ],
  components: {
    Lnb,  // Left side Navigation Bar
    Gnb,  // Global Navigation Bar (Application Service Menus)
    Anb,  // System-Admin Navigation Bar
    Snb,  // Right side Navigation Bar
    Fnb,  // Footer Navigation Bar

    Ftb,  // File-Browser Tool Bar

    JCommComments,
    //JCommMessenger,
    // JCommNotification,
    JFileManager,
    JSnbUserInfo,
    JModalPopupFree,
    JModalPopupHls,
    JModalPopupEquipment,
    JModalPopupHlEquipment,
    JCommentsBoard,
    JNoticePopup,
    JModalOverlayImgPopup
  },
  data: () => ({
    configService: null,
    subItemEnabled: true,
    updateComments: ''
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'commentOpened', 
      'filemanagerOpened', 
      'messengerOpened',
      'noticeOpened', 
      'commentsOpened',
      'userInfoOpened',
      'popupEqOpened',
      'popupHlEqOpened',
      'popupFreeOpened',
      'popupHlsOpened',
      'popupImgOpened',
      'popupEqParams',
      'popupHlEqParams',
      'popupFreeParams',
      'popupHlsParams',
      'imgPath',
      'imgDimention',
      'wrapperOverflowHidden',
      'wrapperOverflowWidth',
      'wrapperOverflowHeight',
      'noticeOptions'
    ]),

    __C_() { return __C },

    ...mapState(__C.STORE_NAMESPACE.CONFIG, ['layout']),

    noticeAvailable() { return this.accountAvailable && this.isHome },
    accountAvailable() { return !!this.account },
    filebrowserActivated() { 
      return this.$route.path.includes('/sysenv/config/filebrowser')
    },
    commentOpen: {
      get() { return this.commentOpened },
      set(val) { this.setCommentOpened(val) }
    },
    envLayout() { return this.layout },
    filemanagerOpen: {
      get() { return this.filemanagerOpened },
      set(val) { this.setFilemanagerOpened(val) }
    },
    messengerOpen: {
      get() { return this.messengerOpened },
      set(val) { this.setMessengerOpened(val) }
    },
    noticeOpen: {
      get() { return this.noticeOpened },
      set(val) { this.setNoticeOpened(val) }
    },
    commentsOpen: {
      get() { return this.commentsOpened },
      set(val) { this.setCommentsOpened(val) }
    },
    userOpen: {
      get() { return this.userInfoOpened },
      set(val) { this.setUserInfoOpened(val) }
    },    
    popupFreeOpen: {
      get() { return this.popupFreeOpened },
      set(val) { this.setPopupFreeOpened({ ...this.popupFreeParams, open: val }) },
    },
    popupHlsOpen: {
      get() { return this.popupHlsOpened },
      set(val) { this.setPopupHlsOpened({ ...this.popupHlsParams, open: val }) },
    },
    popupEqOpen: {
      get() { return this.popupEqOpened },
      set(val) { 
        this.setPopupEqOpened({
          open: val,
          queryid: 0,
          filters: null,
          data: {},
        })
      },
    },
    popupHlEqOpen: {
      get() { return this.popupHlEqOpened },
      set(val) { this.setPopupHlEqOpened({ ...this.popupHlEqParams, open: val }) },
    },
    popupImgOpen: {
      get() { return this.popupImgOpened },
      set(val) { 
        this.setImgPopupOpened({
          open: val,
          imgPath: null,
          dimention: null
        })
      },
    },
    overflowHidden() {
      if(this.$route.path.includes('/export') || this.wrapperOverflowHidden) return 'overflow: hidden;'
      return ''
    },
    styleWrapperOverflowHidden() {
      if(this.wrapperOverflowHidden) return `overflow: hidden; min-width: ${this.wrapperOverflowWidth}px; min-height: ${this.wrapperOverflowHeight}px`
      return 'min-width: 340px'
    },
    isHome() { 
      // return this.$route.path.includes('project_dashboard') && !this.$cookies.isKey("sevenOff")
      // if (this.$route.path.includes('home')) {
      //   this.setNoticeOpened(true)
      //   return true
      // } 
      // if (this.noticeOptions && !this.$route.path.includes('signin')) return this.noticeOptions
      // if (this.noticeOptions && this.$route.path.includes('home')) return this.noticeOptions
      // return this.$route.path.includes('home') && !this.$cookies.isKey("sevenOff")
      // if (this.noticeOptions && this.$route.path.includes('signin')) return this.noticeOptions
      // return !this.$route.path.includes('signin') && !this.$cookies.isKey("sevenOff")

      if (this.$route.path.includes('project_dashboard') && !this.$cookies.isKey("sevenOff")) {
        // project_dashboard 이고 sevenoff 쿠키가 없을때 true
        this.setNoticeOpened(true)
        return true
      } else return false
    }
  },
  watch: {
    layout: {
      handler(val) {
        this.configService.setEnvironment({
          layout: {
            lnb: val.lnb,
            gnb: val.gnb,
            anb: val.anb,
            snb: val.snb,
            fnb: val.fnb
          }
        })
      },
      deep: true
    },
    '$route.path'(val) {
      this.setLayoutLocal(val)
    }
  },
  created() {
    this.configService = new ConfigService()
    let currentConfig = this.configService.getEnvironment()
    if (!currentConfig) {
      if (!this.accountAvailable) this.configService.setEnvironment(_configDefault)
      else this.configService.setEnvironment({
        layout: {
          lnb: {
            visible: true
          },
          gnb: {
            visible: true
          },
          anb: {
            visible: true
          },
          snb: {
            visible: true
          },
          fnb: {
            visible: true
          }
        }
      })
      currentConfig = this.configService.getEnvironment()
    } else {
      if (!currentConfig.layout.anb) {
        currentConfig.layout['anb'] = {
          visible: true
        }
        this.configService.setEnvironment(currentConfig)
      }
    }
    this.setLayout(currentConfig.layout)

    // setTimeout(() => { this.setLayoutLocal(this.$route.path) }, 200)
    this.setLayoutLocal(this.$route.path)
  },
  mounted() {
    window.addEventListener('resize', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onScroll)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setFilemanagerOpened',
      'setMessengerOpened',
      'setNoticeOpened',
      'setUserInfoOpened',

      'setPopupFreeOpened',
      'setPopupHlsOpened',
      'setPopupFreeCallback',
      'setPopupHlEqOpened',
      'setPopupEqOpened',
      'setNoticeOtipons',
      'setImgPopupOpened',
      'setUrlOptions'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.CONFIG, ['setLayout']),

    onScroll(e) {
      let el = document.querySelector('.page_datagrid_content .j_datagrid_title')
      if(!el) return

      let elWrapper = document.querySelector('.page_datagrid_content').getBoundingClientRect()
      let width = elWrapper.width

      el.style.width = `${width}px`
      el.style.marginLeft = `${e.target.scrollLeft}px`
    },
     
    onUserInfoClosed() {
      this.setUserInfoOpened(false)
    },
    onCallSender(d) {
      this.setPopupFreeCallback(d)
    },
    // addPopupFree() {
    //   let i = this.mrComments.length-1
    //   this.mrComments.push(JSON.parse(JSON.stringify(Data.mrComment)))
    //   i += 1

    //   let Component = Vue.extend(JModalPopupFree)
    //   let instance = new Component({
    //       propsData: { 
    //         number: i,
    //         removable: !!i,
    //         package: this.mrHeader.packageNo,
    //         plant: this.mrHeader.plant,
    //         items: this.items,
    //         value: this.mrComments[i]
    //       }
    //   })
    //   instance.$on('input', this.update)
    //   instance.$on('remove', this.remove)
    //   instance.$on('file', this.onFile)
    //   instance.$mount()

    //   this.$refs.appLayout.appendChild(instance.$el)
    //   this.vpInstances.push(instance)
    //   this.vpFiles.push([])

    //   this.vpInstances[0].removable = !!i
    // },
    // removePopupFree() {
    // },
    inputModal(val) {
      if (this.noticeOptions || val.off) val.off = false
      if (val.day_off) {
        //쿠기 설정부분.
        this.$cookies.set('sevenOff','sevenOff')
        const cookies = this.$cookies.get('sevenOff')
      }
      this.setNoticeOtipons(val.off)
      this.setNoticeOpened(val.off)
    },
    inputUser(val) {
      this.setUserInfoOpened(val)
    },
    setLayoutLocal(path) {
      this.setUrlOptions(path)
      this.configService.setEnvironment({
        layout: {
          lnb: {
            visible: false
          },
          gnb: {
            visible: false
          },
          anb: {
            visible: false
          },
          snb: {
            visible: false
          },
          fnb: {
            visible: false
          }
        }
      })
      let currentConfig = this.configService.getEnvironment() 

      let pathPublic = false
      let pathEl = path.substr(1).split('/')
      __C.PUBLIC_URL.forEach(url => { 
        if(pathEl[url.pos] == url.name) {
          pathPublic = true
        }
      })

      //3D Layout
      if(pathPublic) {
        // currentConfig.layout.lnb.visible = false
        // currentConfig.layout.gnb.visible = false
        // currentConfig.layout.anb.visible = false
        // currentConfig.layout.snb.visible = false
        // currentConfig.layout.fnb.visible = false

      } else {
        currentConfig.layout.lnb.visible = true
        currentConfig.layout.gnb.visible = true
        currentConfig.layout.anb.visible = true
        currentConfig.layout.snb.visible = true
        currentConfig.layout.fnb.visible = true

        // System Admin Layout
        if (path.includes('/sysenv')) {
          this.subItemEnabled = false

        // Application Service Layout for every users
        } else {
          this.subItemEnabled = true
          currentConfig.layout.anb.visible = false
        }
      }
      
      this.setLayout(currentConfig.layout)
    },
    onUpdateComment(val) {
      this.updateComments = val
    },
    onRequestAction(request) {
      if(__C.REQUEST_ACTION.TARGET_POPUP_FREE == request.action.target) {
        this.setPopupFreeOpened({
          open: true,
          component: request.action.component,
          filters: request.filters,
          iFilters: request.iFilters,
          data: request.data,
        })
        return
      }
    },
    checkNotification() {
      this.setNoticeOpened(true)
    },
    scrollingFn() {
      // console.log('this is for overflow')
    }
  }
}


</script>
<template>
  <div>
      <v-dialog 
        v-model="opened" 
        :persistent="persistent" 
        :hide-overlay="!overlay" 
        :width="730" 
      >
      <v-card>
        <v-card-title class="hleqPopupWrapper">
          <div class="popup_header">
            <div class="popup_header_item1">EQUIPMENT OVERVIEW Heavy Lift</div>
            <div class="popup_header_item2">DRAG TO MOVE</div>
          </div>
          
          <div v-ripple class="popup_close">
            <button class="button_close" title="Close" @click="onClose">
              <i class="mdi mdi-close"></i>
            </button>
          </div>
          <div class="popup_contents_wrap">
            <div class="popup_contents">
              <div class="popup_contents_box">
              <fieldset>
                <legend>Tag Information</legend>
                <div class="popup_contents_box_info">
                  <div class="contents_wrap">
                    <div class="contents_title">TAG No.</div>
                    <div class="contents_title">Component No.</div>
                    <div class="contents_title">TAG Description</div>
                    <div class="contents_title">Type</div>
                    <div class="contents_title">Dimention</div>
                    <div class="contents_title">Manufacture</div>
                    <div class="contents_title">Design of Lifting Lug</div>
                    <div class="contents_title">Drawing</div>
                    <div class="contents_title">Weight Unit(ton)</div>
                    <div class="contents_title">Weight ATT(ton)</div>
                    <div class="contents_title">Weight Drawing(ton)</div>
                    <div class="contents_title">Main Crain</div>
                    <div class="contents_title">Tali Crain</div>
                    <div class="contents_title">Transport Company</div>
                    <div class="contents_title">Transportation</div>
                    <div style="height: 1.2rem;"></div>
                    <div class="contents_title"></div>
                    <div class="contents_title">PO</div>
                    <div class="contents_title">Delivery ETA</div>
                    <div class="contents_title">Lifting Plan</div>
                  </div>

                  <div class="contents_wrap">
                    <div class="contents_text tag">{{ d.TAG_NO }}</div>
                    <div class="contents_text">{{ d.TAG_NO_COMPONENT }}</div>
                    <div class="contents_text descr" :title="d.TAG_DESCR">{{ d.TAG_DESCR }}</div>
                    <div class="contents_text">{{ d.TAG_TYPE }}</div>
                    <div class="contents_text">{{ d.DIMENSION_M }}</div>
                    <div class="contents_text">{{ d.MANUFACTURE }}</div>
                    <div class="contents_text">{{ d.DESIGN_OF_LIFTING_LUG }}</div>
                    <div class="contents_text">{{ d.DRAWING }}</div>
                    <div class="contents_text">{{ d.WEIGHT_UNIT }}</div>
                    <div class="contents_text">{{ d.WEIGHT_ATT }}</div>
                    <div class="contents_text">{{ d.WEIGHT_DWG }}</div>
                    <div class="contents_text">{{ d.CRANE_MAIN }}</div>
                    <div class="contents_text">{{ d.CRANE_TAIL }}</div>
                    <div class="contents_text">{{ d.TRANSPORT_COMPANY }}</div>
                    <div class="contents_text">{{ d.TRANSPORT }}</div>

                    <div style="height: 1rem;"></div>

                    <div class="contents_date_wrap">
                      <div class="contents_date">
                        <div class="contents_date_text">Plan</div>
                        <div class="contents_date_text">Forecast</div>
                        <div class="contents_date_text">Actual</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.PO_ISSUE_PLAN}}</div>
                        <div class="contents_date_text">{{ d.PO_ISSUE_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.PO_ISSUE_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.DELIVERY_PLAN }}</div>
                        <div class="contents_date_text">{{ d.DELIVERY_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.DELIVERY_ACTUAL }}</div>
                      </div>
                      <div class="contents_date">
                        <div class="contents_date_text">{{ d.LIFTING_PLAN }}</div>
                        <div class="contents_date_text">{{ d.LIFTING_FORECAST }}</div>
                        <div class="contents_date_text">{{ d.LIFTING_ACTUAL }}</div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </fieldset>
              </div>
            </div>
            <div class="popup_preview">
              <fieldset >
                <legend>Object view</legend>
                <div id="preview_box" class="preview_box">
                </div>
              </fieldset>

            </div>
          </div>
          
        </v-card-title>
      </v-card>
    </v-dialog>

  </div>
  
</template>

<script>
import { mapState } from 'vuex'
import { CommunicationService, FetchStreamService, FileService } from "@/services"
import '@/assets/stylus/ui/component/_jHlEquipmentPopup.styl'

import __C from '@/primitives/_constant_'
import QueryLibService from '../services/database.support.service'

import __M from 'moment'

import * as d3 from 'd3'

export default {
  name: 'j-modal-equipment-hl-popup',
  props: {
    params: {
      type: Object,
      default: () => ({
        open: false,
        queryid: 0,
        filters: {},
        data: {
          sender: '',
          persistent: false,
          overlay: true,
        }
      })
    },
    value: null,
  },
  data: () => ({
    queryLibService: null,
    d: {},
    src: '',
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    },
    persistent() { return true },
    overlay() { return true },
  },
  watch: {
    opened(val) {
      if(!val) return
      this.getDataItems()
    },
    'params.filters': {
      handler(val) {
        this.getDataItems()
      },
      deep: true
    },  
  },
  created() {
    this.queryLibService = new QueryLibService()

    this.communicationService = new CommunicationService()
    this.fetchStreamService = new FetchStreamService()
    this.fileService = new FileService()
  },
  methods: {
    onClose() {
      this.opened = false
    },
    setViewDimention() {
      
      let photoViewHeight = 870
      let dimention = this.recentFile.dimention.split(',')

      if(!dimention || dimention.length === 0) return 
      
      let w_ = Number(dimention[0])
      let h_ = Number(dimention[1])
      let inW_ = 0
      let inH_ = 0

      if(h_ <= photoViewHeight) {
        inW_ = w_
        inH_ = h_
      } else {
        let ratio_ = photoViewHeight / h_
        inW_ = Math.round(w_ * ratio_)
        inH_ = photoViewHeight
      }
      d3.select('.photo_viewer')
      .transition()
      .duration(750)
      .style('width', `${inW_}px`)
      .style('height', `${inH_}px`)

      d3.select('.photo').transition().duration(500).style('opacity', 1)
    },
    getDataItems() {
      let filters_ = this.params.filters || {}

      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Equipment Popup Heavy LIftt Skyline',
        filters: `TAG_NO = '${filters_.TAG_NO}'`
      }).then(result => {
        console.log(result)
        this.d = result ? result[0] : {}

        this.src = `${__C.HOST_NAME}/#/pub_3d_viewer/token/${this.d.TAG_NO}/{"d":"eq","w":330,"h":320}`
        // this.src = `${__C.HOST_NAME}/#/pub_3d_viewer/token/3810D000/{"d":"eq","w":330,"h":320}`

        let prevBox = document.getElementById('preview_box')
        console.log(prevBox)
        prevBox.innerHTML = ''

        let iframe = document.createElement('iframe')
        iframe.style.width = '328px'
        iframe.style.height = '298px'
        iframe.style.border = 'none'
        iframe.frameborder = 0
        iframe.scrolling = 'no'
        iframe.src = this.src

        setTimeout(() => {
          prevBox.appendChild(iframe)  
        }, 500);
        
        
      })

    }
  }
}


</script>
